<template>
  <div>
    <div class="row">
      <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
        <!-- Card Start -->
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
            <div class="card">
              <div class="card-body" style="padding: 24px">
                <template class="card-title">
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <a-icon type="file-sync" style="font-size: 30px" />
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                      Jumlah Total Siswa
                    </div>
                  </div>
                </template>
                <p
                  class="card-text"
                  style="font-weight: 600; font-size: 30px; line-height: 1.1"
                >
                  {{ responsedata.total_siswa }}
                </p>
                <a-progress
                  :show-info="false"
                  :stroke-color="{
                    '0%': '#39CDDD' /* Dark blue for educational theme */,
                    '100%': '#4C00FF' /* Lighter blue for consistency */,
                  }"
                  :percent="200"
                ></a-progress>
              </div>
            </div>
          </div>
          <div
            class="col-xs-12 col-sm-6 col-md-3 col-lg-3"
            v-for="(d, index) in responsedata.detail_per_kelas"
            :key="index"
          >
            <div class="card">
              <div class="card-body" style="padding: 24px">
                <template class="card-title">
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <a-icon type="file-protect" style="font-size: 30px" />
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                      {{ d.nama_kelas }}
                    </div>
                  </div>
                </template>
                <p
                  class="card-text"
                  style="font-weight: 600; font-size: 30px; line-height: 1.1"
                >
                  {{ d.jumlah_siswa }}
                </p>
                <a-progress
                  :show-info="false"
                  :stroke-color="{
                    '0%': '#ff6f61' /* Warm coral for early childhood */,
                    '100%':
                      '#ff3d3d' /* Stronger coral to highlight importance */,
                  }"
                  :percent="80"
                ></a-progress>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
            <div class="card">
              <div class="card-body" style="padding: 24px">
                <template class="card-title">
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <a-icon type="usergroup-add" style="font-size: 30px" />
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                      Jumlah Guru
                    </div>
                  </div>
                </template>
                <p
                  class="card-text"
                  style="font-weight: 600; font-size: 30px; line-height: 1.1"
                >
                  {{ responsedata.total_guru }}
                </p>
                <a-progress
                  :show-info="false"
                  :stroke-color="{
                    '0%': '#36d1dc' /* Calm blue for a professional look */,
                    '100%': '#5b86e5' /* Soft gradient for smooth transition */,
                  }"
                  :percent="100"
                ></a-progress>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
            <div class="card">
              <div class="card-body" style="padding: 24px">
                <template class="card-title">
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <a-icon type="file-sync" style="font-size: 30px" />
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                      Total Pembayaran Siswa
                    </div>
                  </div>
                </template>
                <p
                  class="card-text"
                  style="font-weight: 600; font-size: 30px; line-height: 1.1"
                >
                  {{ numberFormat(responsedata.total_pembayaran) }}
                </p>
                <a-progress
                  :show-info="false"
                  :stroke-color="{
                    '0%': '#f7971e' /* Vibrant orange for financial metrics */,
                    '100%':
                      '#ffd200' /* Bright yellow to indicate high values */,
                  }"
                  :percent="20"
                ></a-progress>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
            <div class="card">
              <div class="card-body" style="padding: 24px">
                <template class="card-title">
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <a-icon type="file-sync" style="font-size: 30px" />
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                      Total Pengeluaran
                    </div>
                  </div>
                </template>
                <p
                  class="card-text"
                  style="font-weight: 600; font-size: 30px; line-height: 1.1"
                >
                  {{ numberFormat(responsedata.total_pengeluaran) }}
                </p>
                <a-progress
                  :show-info="false"
                  :stroke-color="{
                    '0%': '#f00000' /* Intense red for high costs */,
                    '100%': '#dc281e' /* Darker red for emphasis */,
                  }"
                  :percent="80"
                ></a-progress>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
            <div class="card">
              <div class="card-body" style="padding: 24px">
                <template class="card-title">
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <a-icon type="file-sync" style="font-size: 30px" />
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                      Pemasukan Lainnya
                    </div>
                  </div>
                </template>
                <p
                  class="card-text"
                  style="font-weight: 600; font-size: 30px; line-height: 1.1"
                >
                  {{ numberFormat(responsedata.total_pemasukan) }}
                </p>
                <a-progress
                  :show-info="false"
                  :stroke-color="{
                    '0%': '#00c6ff' /* Bright blue for additional income */,
                    '100%': '#0072ff' /* Darker blue for distinction */,
                  }"
                  :percent="60"
                ></a-progress>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
            <div class="card">
              <div class="card-body" style="padding: 24px">
                <template class="card-title">
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <a-icon type="usergroup-add" style="font-size: 30px" />
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                      Yang Belum Bayar Bulan Ini
                    </div>
                  </div>
                </template>
                <p
                  class="card-text"
                  style="font-weight: 600; font-size: 30px; line-height: 1.1"
                >
                  {{ numberFormat(responsedata.total_tunggakan) }}
                </p>
                <a-progress
                  :show-info="false"
                  :stroke-color="{
                    '0%': '#ff8c00' /* Bold orange to indicate overdue payments */,
                    '100%': '#ff4500' /* Darker orange for urgency */,
                  }"
                  :percent="100"
                ></a-progress>
              </div>
            </div>
          </div>
        </div>
        <!-- Table Pembayaran Terakhir Start -->
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <a-card>
              <strong>Data Pembayaran Terakhir</strong>
              <a-table
                :data-source="tablepembayaranterakhir.data"
                :columns="tablepembayaranterakhir.columns"
                :pagination="{
                  hideOnSinglePage: false,
                  defaultPageSize: 10,
                  showQuickJumper: false,
                  showSizeChanger: true,
                  size: 'small',
                  pageSizeOptions: [
                    '10',
                    '20',
                    '30',
                    '50',
                  ],
                }"
                class="mt-4"
              >
                <div
                  slot="filterDropdown"
                  slot-scope="{
                    setSelectedKeys,
                    selectedKeys,
                    confirm,
                    clearFilters,
                    column,
                  }"
                  style="padding: 8px"
                >
                  <a-input
                    v-ant-ref="(c) => (searchInput = c)"
                    :placeholder="`Search ${column.dataIndex}`"
                    :value="selectedKeys[0]"
                    style="width: 188px; margin-bottom: 8px; display: block"
                    @change="
                      (e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    "
                    @pressEnter="
                      () =>
                        handleSearch(selectedKeys, confirm, column.dataIndex)
                    "
                  />
                  <a-button
                    type="primary"
                    icon="search"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="
                      () =>
                        handleSearch(selectedKeys, confirm, column.dataIndex)
                    "
                  >
                    Search
                  </a-button>
                  <a-button
                    size="small"
                    style="width: 90px"
                    @click="() => handleReset(clearFilters)"
                  >
                    Reset
                  </a-button>
                </div>
                <a-icon
                  slot="filterIcon"
                  slot-scope="filtered"
                  type="search"
                  :style="{ color: filtered ? '#108ee9' : undefined }"
                />
                <template
                  slot="customRender"
                  slot-scope="text, record, index, column"
                >
                  <span
                    v-if="searchText && searchedColumn === column.dataIndex"
                  >
                    <template
                      v-for="(fragment, i) in text
                        .toString()
                        .split(
                          new RegExp(
                            `(?<=${searchText})|(?=${searchText})`,
                            'i'
                          )
                        )"
                    >
                      <mark
                        v-if="
                          fragment.toLowerCase() === searchText.toLowerCase()
                        "
                        :key="i"
                        class="highlight"
                        >{{ fragment }}</mark
                      >
                      <template v-else>{{ fragment }}</template>
                    </template>
                  </span>
                  <template v-else>
                    {{ text }}
                  </template>
                </template>
                <span slot="dateFormat" slot-scope="text">
                  {{ formatDate(text) }}
                </span>
                <span slot="formatCurrency" slot-scope="text">
                  {{ numberFormat(text) }}
                </span>
              </a-table>
            </a-card>
          </div>
        </div>
        <!-- Table Pembayaran Terakhir End -->
        <!-- Table Pembayaran Terakhir Start -->
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-4">
            <a-card>
              <strong>Data Pengeluaran</strong>
              <a-table
                :data-source="tablepengeluaran.data"
                :columns="tablepengeluaran.columns"
                :pagination="{
                  hideOnSinglePage: false,
                  defaultPageSize: 10,
                  showQuickJumper: false,
                  showSizeChanger: true,
                  size: 'small',
                  pageSizeOptions: [
                    '10',
                    '20',
                    '30',
                    '50',
                  ],
                }"
                class="mt-4"
              >
                <div
                  slot="filterDropdown"
                  slot-scope="{
                    setSelectedKeys,
                    selectedKeys,
                    confirm,
                    clearFilters,
                    column,
                  }"
                  style="padding: 8px"
                >
                  <a-input
                    v-ant-ref="(c) => (searchInput = c)"
                    :placeholder="`Search ${column.dataIndex}`"
                    :value="selectedKeys[0]"
                    style="width: 188px; margin-bottom: 8px; display: block"
                    @change="
                      (e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    "
                    @pressEnter="
                      () =>
                        handleSearch(selectedKeys, confirm, column.dataIndex)
                    "
                  />
                  <a-button
                    type="primary"
                    icon="search"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="
                      () =>
                        handleSearch(selectedKeys, confirm, column.dataIndex)
                    "
                  >
                    Search
                  </a-button>
                  <a-button
                    size="small"
                    style="width: 90px"
                    @click="() => handleReset(clearFilters)"
                  >
                    Reset
                  </a-button>
                </div>
                <a-icon
                  slot="filterIcon"
                  slot-scope="filtered"
                  type="search"
                  :style="{ color: filtered ? '#108ee9' : undefined }"
                />
                <template
                  slot="customRender"
                  slot-scope="text, record, index, column"
                >
                  <span
                    v-if="searchText && searchedColumn === column.dataIndex"
                  >
                    <template
                      v-for="(fragment, i) in text
                        .toString()
                        .split(
                          new RegExp(
                            `(?<=${searchText})|(?=${searchText})`,
                            'i'
                          )
                        )"
                    >
                      <mark
                        v-if="
                          fragment.toLowerCase() === searchText.toLowerCase()
                        "
                        :key="i"
                        class="highlight"
                        >{{ fragment }}</mark
                      >
                      <template v-else>{{ fragment }}</template>
                    </template>
                  </span>
                  <template v-else>
                    {{ text }}
                  </template>
                </template>
                <span slot="dateFormat" slot-scope="text">
                  {{ formatDate(text) }}
                </span>
                <span slot="formatCurrency" slot-scope="text">
                  {{ numberFormat(text) }}
                </span>
              </a-table>
            </a-card>
          </div>
        </div>
        <!-- Table Pembayaran Terakhir End -->
      </div>
      <!-- Right List Start -->
      <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <a-card>
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-4">
                <strong style="font-size: 16">Birth Day List</strong>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" v-for="(data, index) in responsedata.data_siswa" :key="index">
                      <div class="row">
                        <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                          <div class="exam-item">
                            <h5>{{ data.name }}</h5>
                            <label class="mb-1">Birth Date: {{ formatDate(data.birth_date, 'DD/MM/YYYY') }}</label
                            ><br />
                            <label>Kelas: {{ data.class }}</label>
                          </div>
                        </div>
                        <div
                          class="col-xs-2 col-sm-2 col-md-2 col-lg-2"
                          style="align-content: center"
                        >
                          <a href="javascript:void(0)">
                            <a-icon type="right" class="text-primary" />
                          </a>
                        </div>
                      </div>
                      <a-divider style="margin: 10px 0" />
                    </div>
                  </div>
                </div>
                <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <a-pagination size="small" :total="50" show-size-changer />
                </div> -->
              </div>
            </a-card>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-3">
            <a-card>
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-4">
                <strong style="font-size: 16">Total Saldo Keuangan</strong>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-3" v-for="(data, index) in responsedata.data_keuangan" :key="index">
                      <div class="row">
                        <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                          <div class="exam-item">
                            <h5>{{ data.name }}</h5>
                            <label>{{ numberFormat(data.amount) }}</label>
                          </div>
                        </div>
                        <div
                          class="col-xs-2 col-sm-2 col-md-2 col-lg-2"
                          style="align-content: center"
                        >
                          <a href="javascript:void(0)">
                            <a-icon type="right" class="text-primary" />
                          </a>
                        </div>
                      </div>
                      <a-divider style="margin: 10px 0" />
                    </div>
                  </div>
                </div>
                <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <a-pagination size="small" :total="50" show-size-changer />
                </div> -->
              </div>
            </a-card>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-3">
            <a-card>
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-4">
                <strong style="font-size: 16">Data Guru</strong>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12"  v-for="(data, index) in responsedata.data_guru" :key="index">
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="row">
                        <div
                          class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mr-1"
                          style="align-content: center"
                        >
                          <a-avatar :size="45" icon="user" />
                        </div>
                        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                          <div class="exam-item">
                            <h5>{{ data.name + ' ('+ data.gender +')' }}</h5>
                            <label>Birth: {{ formatDate(data.birth_date, 'DD/MM/YYYY') }}</label>
                          </div>
                        </div>
                      </div>
                      <a-divider style="margin: 10px 0" />
                    </div>
                  </div>
                </div>
                <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <a-pagination size="small" :total="50" show-size-changer />
                </div> -->
              </div>
            </a-card>
          </div>
        </div>
      </div>
      <!-- Right List End -->
    </div>
  </div>
</template>
<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'

export default {
  created() {
    this.getAllData()
  },
  data() {
    return {
      responsedata: {
        total_siswa: 0,
        total_guru: 0,
        detail_per_kelas: [],
        total_pembayaran: 0,
        total_pengeluaran: 0,
        total_pemasukan: 0,
        total_tunggakan: 0,
        data_siswa: [],
        data_guru: [],
        data_pembayaran: [],
        data_pengeluaran: [],
        data_keuangan: [],
      },

      searchText: '',
      searchInput: null,
      searchedColumn: '',
      tablepembayaranterakhir: {
        data: [],
        columns: [
          {
            title: 'Name',
            dataIndex: 'name',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) =>
              record.name
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Payment Date',
            dataIndex: 'payment_date',
            scopedSlots: {
              customRender: 'dateFormat',
            },
          },
          {
            title: 'Amount',
            dataIndex: 'amount',
            scopedSlots: {
              customRender: 'formatCurrency',
            },
          },
          {
            title: 'Notes',
            dataIndex: 'notes',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) =>
              record.notes
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
        ],
      },
      tablepengeluaran: {
        data: [],
        columns: [
          {
            title: 'Tanggal',
            dataIndex: 'tgl',
            scopedSlots: {
              customRender: 'dateFormat',
            },
          },
          {
            title: 'Kategori',
            dataIndex: 'kategori_name',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) =>
              record.kategori_name
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Jumlah',
            dataIndex: 'jumlah',
            scopedSlots: {
              customRender: 'formatCurrency',
            },
          },
          {
            title: 'Keterangan',
            dataIndex: 'keterangan',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) =>
              record.keterangan
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Referensi',
            dataIndex: 'referensi',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) =>
              record.referensi
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
        ],
      },
    }
  },
  methods: {
    moment,
    async getAllData() {
      var res = await lou.customUrlGet2(
        'sekolah/dashboard',
        false,
        false,
        false,
        true,
      )
      if (res) {
        this.responsedata = res.data
        this.tablepembayaranterakhir.data = res.data.data_pembayaran
        this.tablepengeluaran.data = res.data.data_pengeluaran
      }
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },

    dynamicColors() {
      var r = lou.randomColors()
      console.log('r', r)
      return r
    },
    formatDate(value, toformat = 'DD-MM-YYYY') {
      return moment(value).format(toformat)
    },
    numberFormat(val) {
      return lou.curency(val)
    },
  },
}
</script>

<style scoped>
[data-kit-theme="default"] .ant-table-thead > tr > th {
  background: none !important;
}
[data-kit-theme="dark"] .ant-table-thead > tr > th {
  background: none !important;
}

[data-kit-theme="default"] body {
  background: rgb(235, 237, 240) !important;
}

.exam-item {
  padding: 10px 0;
  align-content: center;
}
</style>
